<template>
    <div class="businessGroupWh">
        <slider @load-table="handleLoadTable"></slider>
        <tableList :id="selectId" :table-data="tableData"></tableList>
    </div>
</template>

<script>
import slider from './slider';
import tableList from './tableList';
export default {
    components: { slider, tableList },
    props: { },
    data() {
        return {
            tableData: {},
            selectId: '',
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 加载表格
        handleLoadTable(id, data) {
            this.tableData = data;
            this.selectId = id;
        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.businessGroupWh
    font-size .16rem
    overflow hidden
</style>